.rbc-toolbar {
  @apply sticky top-0 bg-white lg:bg-transparent z-[49] pt-[10px] pb-[10px] lg:pb-0;
}

.rbc-toolbar .rbc-toolbar-label {
  @apply pt-2 sm:pt-0 sm:text-right font-body2 text-lg capitalize;
}

.rbc-toolbar .rbc-btn-group button {
  @apply font-sans;
}

.ruleCalendar .rbc-row-segment {
  @apply p-0;
}

.ruleCalendar .rbc-event, .ruleCalendar .rbc-event.rbc-selected {
  @apply bg-transparent p-0 border-0 !outline-none;
}

.eventCalendar .rbc-row-segment {
  @apply px-1 pb-1;
}

.rbc-month-view, .rbc-time-view {
  @apply bg-white border-zinc-200 rounded-md;
}

.rbc-month-header, .rbc-time-header {
  @apply shadow-md z-[48];
}

.rbc-month-header .rbc-header, .rbc-time-header .rbc-header {
  @apply font-body2 font-normal text-base capitalize py-3 border-0;
}

.rbc-allday-cell {
  @apply hidden;
}

.rbc-off-range-bg {
  @apply bg-gray-100;
}

.rbc-selected-cell {
  @apply bg-slate-300 bg-none border-0;
}

.rbc-now .calendar-date {
  @apply inline-flex items-center justify-center bg-gray-800 text-white rounded-full w-[25px] h-[25px];
}

.rbc-time-header-content {
  @apply border-l-0;
}

.rbc-time-content {
  @apply border-t-0;
}

.rbc-time-slot {
  @apply pt-1 text-sm font-body2 font-normal;
}

.rbc-current-time-indicator {
  @apply bg-slate-500;
}

.rbc-today {
  @apply bg-sky-50/80;
}

.rbc-events-container {
  @apply ml-1 border-l-0;
}

.rbc-day-slot .rbc-event-label {
  @apply w-full font-body2 opacity-75 text-xs mb-[0.3rem];
}

.rbc-header.rbc-today {
  @apply border-x ml-px;
}

.rbc-toolbar button {
  @apply bg-white;
}

.eventCalendar .rbc-toolbar .rbc-btn-group:last-of-type button:nth-child(2) {
  @apply hidden lg:inline-block;
}

.eventCalendar .rbc-show-more {
  @apply hidden lg:block;
}
