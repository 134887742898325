.moveNote blockQuote {
  @apply ml-3 border-l-4 border-neutral-300 px-3 py-1 text-neutral-400 my-0 not-italic;
}

.moveNote ul {
  @apply ml-6 list-disc;
}

.moveNote ol {
  @apply ml-6 list-decimal;
}

.moveNote p {
  @apply min-h-[12px] leading-6;
}
