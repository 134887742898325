@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: hsl(0 0% 100%);
    --foreground: hsl(222.2 47.4% 11.2%);
 
    --muted: hsl(210 40% 96.1%);
    --muted-foreground: hsl(215.4 16.3% 46.9%);
 
    --popover: hsl(0 0% 100%);
    --popover-foreground: hsl(222.2 47.4% 11.2%);
 
    --border: hsl(214.3 31.8% 91.4%);
    --input: hsl(214.3 31.8% 91.4%);
 
    --card: hsl(0 0% 100%);
    --card-foreground: hsl(222.2 47.4% 11.2%);
 
    --primary: rgb(250, 55, 44);
    --primary-foreground: hsl(210 40% 98%);
 
    --secondary: hsl(210 40% 96.1%);
    --secondary-foreground: hsl(222.2 47.4% 11.2%);
 
    --accent: hsl(210 40% 96.1%);
    --accent-foreground: hsl(222.2 47.4% 11.2%);
 
    --destructive: hsl(0 100% 50%);
    --destructive-foreground: hsl(210 40% 98%);
 
    --ring: hsl(215 20.2% 65.1%);
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: hsl(224 71% 4%);
    --foreground: hsl(213 31% 91%);
 
    --muted: hsl(223 47% 11%);
    --muted-foreground: hsl(215.4 16.3% 56.9%);
 
    --accent: hsl(216 34% 17%);
    --accent-foreground: hsl(210 40% 98%);
 
    --popover: hsl(224 71% 4%);
    --popover-foreground: hsl(215 20.2% 65.1%);
 
    --border: hsl(216 34% 17%);
    --input: hsl(216 34% 17%);
 
    --card: hsl(224 71% 4%);
    --card-foreground: hsl(213 31% 91%);
 
    --primary: hsl(210 40% 98%);
    --primary-foreground: hsl(222.2 47.4% 1.2%);
 
    --secondary: hsl(222.2 47.4% 11.2%);
    --secondary-foreground: hsl(210 40% 98%);
 
    --destructive: hsl(0 63% 31%);
    --destructive-foreground: hsl(210 40% 98%);
 
    --ring: hsl(216 34% 17%);
 
    --radius: 0.5rem;
  }
}

@layer base {
  html, body, #root {
    padding: 0;
    margin: 0;
    font-feature-settings: "rlig" 1, "calt" 1;
    -webkit-tap-highlight-color: transparent;
  }
}

/* hide close icon from google maps info window */
.gm-ui-hover-effect {
  display: none !important;
}

/* scrollbar styles for chrome */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(133, 133, 133, .6);
  border-radius: 20px;
  border: transparent;
}

:root .rdp {
  --rdp-accent-color: rgb(100 116 139 / .9);
  --rdp-background-color: rgba(250 55 44 / .1);
}