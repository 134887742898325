// cube animation
$w: 48px;
$h: 56px;
$xspace: calc($w/2);
$yspace: calc(($h/4) - 1px);
$speed: 1.5s;

// logo animation
$logoFillFrom: rgba(250, 55, 44, 1);
$logoFillTo: rgba(250, 55, 44, .3);
$animationDuration: 2.8s;
$animationEasing: cubic-bezier(0.77, 0, 0.175, 1);
$animationDelay: 0s;
$animationStep: 0.1s;
$animationFillMode: forwards;
$animationIterationCount: infinite;

.boxes-spinner {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-$h, -$w);
}

.box {
  width: $w;
  height: $h;
  position: absolute;
  transition: all ease .3s;
  background: url('./box.svg') no-repeat top center;
}

.box1 {
  animation: box1 $speed ease-out infinite;
}
.box2 {
  animation: box2 $speed ease-out infinite;
}
.box3 {
  animation: box3 $speed ease-out infinite;
  z-index: 2;
}
.box4 {
  animation: box4 $speed ease-out infinite;
}

@keyframes box1 {
  0%, 40% { 
    /* compose logo *//* 1 on 3 *//* L-shape */
    transform: translate(0,0);
  } 50% {
    /* pre-box */
    transform: translate($xspace,-$yspace);
  } 60%, 100% {
    /* box *//* compose logo */
    transform: translate($xspace*2,0);
  }
}

@keyframes box2 {
  0%, 20% { 
    /* compose logo *//* 1 on 3 */
    transform: translate($xspace*2, 0px);
  } 40%, 100% { 
    /* L-shape *//* box *//* compose logo */
    transform: translate($xspace*3, $yspace);
  }
}

@keyframes box3 {
  0% { 
    /* compose logo */
    transform: translate($xspace*3, $yspace);
  } 20%, 60% { 
    /* 1 on 3 *//* L-shape *//* box */
    transform: translate($xspace*2, $yspace*2);
  } 90%, 100% { 
    /* compose logo */
    transform: translate($xspace, $yspace);
  }
}

@keyframes box4 {
  0%, 60% {
    /* compose logo *//* 1 on 3 *//* L-shape *//* box */
    transform: translate($xspace, $yspace);
  } 90%, 100% { 
    /* compose logo */
    transform: translate(0, 0);
  }
}

.animated-logo {
  @-webkit-keyframes animate-svg-fill {
    0% {
      fill: $logoFillFrom;
    }
    50% {
      fill: $logoFillTo;
    }
    100% {
      fill: $logoFillFrom;
    }
  }
  @keyframes animate-svg-fill {
    0% {
      fill: $logoFillFrom;
    }
    50% {
      fill: $logoFillTo;
    }
    100% {
      fill: $logoFillFrom;
    }
  }

  .svg-elem-1 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 0)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 0)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-2 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 1)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 1)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-3 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 2)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 2)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-4 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 3)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 3)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-5 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 4)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 4)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-6 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 5)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 5)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-7 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 6)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 6)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-8 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 7)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 7)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-9 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 8)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 8)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-10 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 9)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 9)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-11 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 10)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 10)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-12 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 11)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 11)) $animationFillMode $animationIterationCount;
  }

  .svg-elem-13 {
    -webkit-animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 12)) $animationFillMode $animationIterationCount;
            animation: animate-svg-fill $animationDuration $animationEasing ($animationDelay + ($animationStep * 12)) $animationFillMode $animationIterationCount;
  }
}